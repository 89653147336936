import { deleteSourceSelector, updateSourceSelectors } from '../../../../scripts/sourceSelector';
import CheckboxField from '../../fields/CheckBoxField';
import NameField from '../../fields/NameField';
import NumberField from '../../fields/NumberField';
import ProcessorChoiceField from '../../fields/ProcessorChoiceField';
import { DisplayField } from '../../fields/Styles';
import { SourceSelectorIcon } from '../../styling/Icons';
import { getDefaultId } from '../../table/tableUtils';
import { actionTypes } from '../../utils/installationTypes';
import ShowSourceMatrixTabButton from './actions/ShowSourceMatrixTabButton';
import SourceConfigField from './fields/SourceConfigField';
import SourceMatrixChoiceField, { disableSourceMatrixChoiceField, hideSourceMatrixChoiceField } from './fields/SourceMatrixChoiceField';
import SourceSelectorAddressField, { disableSourceSelectorAddressField, hideSourceSelectorAddressField } from './fields/SourceSelectorAddressField';
import SourceSelectorTypeChoiceField from './fields/SourceSelectorTypeChoiceField';
import { addNewSourceSelector } from './sourceSelectorUtils';
import { Placeholder } from '../../table/styles/TableStyles';
import AddObjectButton from '../../table/AddObjectButton';
import { InfoIcon } from '../../../UiComponents/Icons';
import theme from '../../../../UI/theme';
import { sourceSelectorTypes } from './sourceSelectorTypes';


const idField = 'sourceSelectorId';

export const getSourceSelectorTableProps = ({ sourceSelectors, processors, sources, sourceMatrixes, customerId, setSelectedTab }) => {

    const getColumns = (sourceSelectors) => [{
        key: 'name',
        displayName: 'Name',
        form: true,
        width: '150px',
        formValidate: (sourceSelector) => {
            return sourceSelector.name ? null : { type: 'ERROR', message: 'Name is required' };
        },
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <NameField
                object={sourceSelector}
                updateObject={updateSourceSelector}
                objects={sourceSelectors}
                idField={'sourceSelectorId'}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        formValidate: (sourceSelector) => {
            return sourceSelector.processorId ? null : { type: 'ERROR', message: 'Processor is required' };
        },
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <ProcessorChoiceField
                object={sourceSelector}
                processors={processors}
                updateObject={updateSourceSelector}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'type',
        displayName: 'Type',
        width: '150px',
        form: true,
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceSelectorTypeChoiceField
                type={sourceSelector.type}
                setValue={(value) => updateSourceSelector({ type: value })}
                cellControl={cellControl}
                sourceMatrixes={sourceMatrixes}
                setSelectedTab={setSelectedTab}
            />
        )
    }, {
        key: 'address',
        displayName: <>
            Object Address <InfoIcon
                style={{ fontSize: '16px', color: theme.colors.greenEnergy}}
                onClick={() => {window.open('https://help.waved.co/en/articles/9595553-processor-integrations', "_blank")}}
            />
        </>,
        width: '300px',
        form: true,
        formValidate: (sourceSelector) => {
            if (sourceSelector.type === sourceSelectorTypes.MATRIX) return null;
            return sourceSelector.address ? null : { type: 'ERROR', message: 'Address is required' };
        },
        hideInItem: (sourceSelector) => disableSourceSelectorAddressField(sourceSelector),
        hide: hideSourceSelectorAddressField(sourceSelectors),
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceSelectorAddressField
                sourceSelector={sourceSelector}
                updateSourceSelector={updateSourceSelector}
                sourceSelectors={sourceSelectors}
                processors={processors}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'useLinkedAddress',
        displayName: 'Linked',
        width: '50px',
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <CheckboxField
                checked={sourceSelector.useLinkedAddress}
                setValue={() => updateSourceSelector({ useLinkedAddress: +(!sourceSelector.useLinkedAddress) })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'linkedAddress',
        displayName: 'Linked Address',
        width: '300px',
        form: true,
        hideInItem: (sourceSelector) => !sourceSelector.useLinkedAddress,
        hide: !sourceSelectors?.some(sourceSelector => sourceSelector.useLinkedAddress),
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceSelectorAddressField
                sourceSelector={sourceSelector}
                updateSourceSelector={updateSourceSelector}
                addressField={'linkedAddress'}
                sourceSelectors={sourceSelectors}
                processors={processors}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'sourceMatrixId',
        displayName: 'Source Matrix',
        width: '300px',
        form: true,
        hideInItem: (sourceSelector) => disableSourceMatrixChoiceField(sourceSelector),
        hide: hideSourceMatrixChoiceField(sourceSelectors),
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceMatrixChoiceField
                sourceSelector={sourceSelector}
                sourceMatrixes={sourceMatrixes.filter(sourceMatrix => sourceMatrix.processorId === sourceSelector.processorId)}
                updateSourceSelector={updateSourceSelector}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'matrixOutputNumber',
        displayName: 'Output Number',
        width: '100px',
        form: true,
        hide: hideSourceMatrixChoiceField(sourceSelectors),
        hideInItem: (sourceSelector) => disableSourceMatrixChoiceField(sourceSelector),
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <NumberField
                value={sourceSelector.matrixOutputNumber}
                setValue={(value) => updateSourceSelector({ matrixOutputNumber: value })}
                cellState={cellControl.cellState}
                disabled={disableSourceMatrixChoiceField(sourceSelector)}
            />
        )
    }, {
        key: 'mapping',
        displayName: 'Source Config.',
        width: '150px',
        render: (sourceSelector, updateSourceSelector, cellControl) => (
            <SourceConfigField
                sourceConfig={sourceSelector.sourceConfig}
                updateSourceConfig={(sourceConfig) => updateSourceSelector({ sourceConfig })}
                sources={sources.filter(source => source.processorId === sourceSelector.processorId)}
                cellState={cellControl.cellState}
            />
        )
    }/*, { // Why should a sourceSelector have an orderIndex? was it a mistake and intended for source?
        key: 'orderIndex',
        displayName: 'Order',
        width: '50px',
        render: (object, updateObject, cellControl) => (
            <NumberField
                value={object.orderIndex}
                setValue={(value) => updateObject({ orderIndex: value })}
                cellState={cellControl.cellState}
            />
        )
    }*/]

    const saveObjects = async (changedSourceSelectors) => {
        try {
            await updateSourceSelectors(changedSourceSelectors);
        } catch (err) {
            console.log('Failed to update sourceselectors');
        }
    }

    const deleteObject = async (sourceSelector) => {
        try {
            await deleteSourceSelector(sourceSelector.sourceSelectorId);
        } catch (err) {
            console.log('Error failed to delete sourceSelector');
        }
    }

    const addObject = processors.length ? (newSourceSelector) => {
        newSourceSelector.type = newSourceSelector.type || sourceSelectorTypes.NORMAL;
        addNewSourceSelector(newSourceSelector, sourceSelectors, processors, customerId)
    } : null;

    const getListItemProps = (sourceSelector) => {
        return {
            displayName: sourceSelector.name,
            Icon: SourceSelectorIcon
        }
    }

    const getActions = (sourceSelector) => [{
        key: 'addSourceMatrix',
        type: actionTypes.LIST,
        render: () => (
            <ShowSourceMatrixTabButton setSelectedTab={setSelectedTab} />
        )
    }];

    const getPlaceholder = () => {
        return (
            <Placeholder>
                <p>No source selectors added.</p>
                <AddObjectButton
                    title='source selector' 
                    objects={sourceSelectors}
                    onSubmit={addObject}
                    columns={getColumns(sourceSelectors)}
                    overrideButtonStyle={{
                        title: 'Add source selector',
                    }}
                />
            </Placeholder>
        )
    }

    return {
        title: 'Source selectors',
        getColumns,
        parentObjects: sourceSelectors,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}
